import { default as indexCsUHEyt9s9Meta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/admin/authority/index.vue?macro=true";
import { default as indexMcO3ZOsYLXMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/admin/code/index.vue?macro=true";
import { default as indexwthiYCAMXcMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/admin/menu/index.vue?macro=true";
import { default as indexsmI7w4mbAWMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/admin/user/index.vue?macro=true";
import { default as indexuyJE8VlMEuMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/index.vue?macro=true";
import { default as login0lCpnNybvyMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/login.vue?macro=true";
import { default as index9iV0Gks3QYMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/scratch/category/index.vue?macro=true";
import { default as indexnatCh23iqfMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/scratch/daily/index.vue?macro=true";
import { default as _91id_933CBCBZxvNyMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/scratch/game/[id].vue?macro=true";
import { default as indexLZ5B4sALboMeta } from "/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/scratch/game/index.vue?macro=true";
export default [
  {
    name: "admin-authority",
    path: "/admin/authority",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/admin/authority/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-code",
    path: "/admin/code",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/admin/code/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-menu",
    path: "/admin/menu",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/admin/menu/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-user",
    path: "/admin/user",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/admin/user/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login0lCpnNybvyMeta || {},
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "scratch-category",
    path: "/scratch/category",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/scratch/category/index.vue").then(m => m.default || m)
  },
  {
    name: "scratch-daily",
    path: "/scratch/daily",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/scratch/daily/index.vue").then(m => m.default || m)
  },
  {
    name: "scratch-game-id",
    path: "/scratch/game/:id()",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/scratch/game/[id].vue").then(m => m.default || m)
  },
  {
    name: "scratch-game",
    path: "/scratch/game",
    component: () => import("/home/gitlab/builds/Xh5g_NY8/0/scratch-book/deploy/backoffice/pages/scratch/game/index.vue").then(m => m.default || m)
  }
]